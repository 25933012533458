import React from 'react';

const LogoIcon = () => {
  

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
    >
      <path
        d="M0 0 C1.12591553 0.00523682 2.25183105 0.01047363 3.41186523 0.01586914 C4.62680664 0.0190918 5.84174805 0.02231445 7.09350586 0.02563477 C8.37290039 0.03401367 9.65229492 0.04239258 10.97045898 0.05102539 C12.2537207 0.05553711 13.53698242 0.06004883 14.85913086 0.06469727 C18.04211921 0.07652994 21.22501661 0.09301471 24.40795898 0.11352539 C24.40795898 2.75352539 24.40795898 5.39352539 24.40795898 8.11352539 C19.78795898 8.44352539 15.16795898 8.77352539 10.40795898 9.11352539 C10.40795898 11.09352539 10.40795898 13.07352539 10.40795898 15.11352539 C13.37795898 15.44352539 16.34795898 15.77352539 19.40795898 16.11352539 C19.40795898 18.75352539 19.40795898 21.39352539 19.40795898 24.11352539 C17.75795898 24.44352539 16.10795898 24.77352539 14.40795898 25.11352539 C14.40795898 27.42352539 14.40795898 29.73352539 14.40795898 32.11352539 C11.76795898 32.11352539 9.12795898 32.11352539 6.40795898 32.11352539 C6.36554175 29.78057764 6.36702961 27.44649972 6.40795898 25.11352539 C6.73795898 24.78352539 7.06795898 24.45352539 7.40795898 24.11352539 C7.44888836 21.78055106 7.45037622 19.44647314 7.40795898 17.11352539 C6.03510742 17.13092773 6.03510742 17.13092773 4.63452148 17.14868164 C2.85174805 17.1622168 2.85174805 17.1622168 1.03295898 17.17602539 C-0.15040039 17.18762695 -1.33375977 17.19922852 -2.55297852 17.21118164 C-5.59204102 17.11352539 -5.59204102 17.11352539 -7.59204102 16.11352539 C-7.59204102 13.47352539 -7.59204102 10.83352539 -7.59204102 8.11352539 C-5.94204102 8.11352539 -4.29204102 8.11352539 -2.59204102 8.11352539 C-2.87047852 6.41196289 -2.87047852 6.41196289 -3.15454102 4.67602539 C-3.29891602 3.50040039 -3.44329102 2.32477539 -3.59204102 1.11352539 C-2.59204102 0.11352539 -2.59204102 0.11352539 0 0 Z "
        fill="#57B784"
        transform="translate(7.592041015625,-0.113525390625)"
      />
      <path
        d="M0 0 C3.63 0 7.26 0 11 0 C11 2.64 11 5.28 11 8 C9.35 8.33 7.7 8.66 6 9 C6 11.31 6 13.62 6 16 C3.36 16 0.72 16 -2 16 C-2.04241723 13.66705225 -2.04092937 11.33297433 -2 9 C-1.67 8.67 -1.34 8.34 -1 8 C-0.76924918 6.65252916 -0.58846937 5.29622435 -0.4375 3.9375 C-0.293125 2.638125 -0.14875 1.33875 0 0 Z "
        fill="#00A1DE"
        transform="translate(16,16)"
      />
      <path
        d="M0 0 C2.81256281 -0.02684312 5.62487241 -0.04676188 8.4375 -0.0625 C9.23994141 -0.07087891 10.04238281 -0.07925781 10.86914062 -0.08789062 C12.91295221 -0.0965143 14.95683902 -0.0522815 17 0 C18 1 18 1 18.0625 4.0625 C18.041875 5.031875 18.02125 6.00125 18 7 C14.56326187 9.10022886 11.86383187 9.24140313 7.875 9.1875 C6.82054687 9.18105469 5.76609375 9.17460937 4.6796875 9.16796875 C2 9 2 9 0 8 C0 5.36 0 2.72 0 0 Z "
        fill="#E4CC37"
        transform="translate(0,8)"
      />
      <path
        d="M0 0 C2.33944736 0.28730055 4.6739143 0.61936779 7 1 C7 3.31 7 5.62 7 8 C4.36 8 1.72 8 -1 8 C-1.04241723 5.66705225 -1.04092937 3.33297433 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z "
        fill="#003854"
        transform="translate(15,24)"
      />
    </svg>
  );
};

export default LogoIcon;
