import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
  pathcls1: {
    fill: '#003955',
  },
  pathcls2: {
    fill: '#00a0de',
  },
  pathcls3: {
    fill: '#e4cb3a',
  },
  pathcls4: {
    fill: '#5cb885',
  },
});

export function LogoVConsyst() {
  const classes = useStyles();
 
  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 348.35 68.36"
    >
      <path
        className={classes.pathcls1}
        d="M108,19.09c2.36,0,2.82.66,2,2.83L98.7,51.08c-.59,1.65-1.45,2.17-3.29,2.17H91.54c-1.84,0-2.76-.52-3.35-2.17L77,21.92c-.79-2.17-.33-2.83,2-2.83H83c2.82,0,3.61.4,4.2,2.43l4.47,12.81a36.86,36.86,0,0,1,1.45,6.57l.19,1.18h.46a34.06,34.06,0,0,1,1.58-7.75l4.4-12.74c.72-2.1,1.31-2.5,4.14-2.5Z"
      />
      <path
        className={classes.pathcls1}
        d="M113,32c0-8.86,5.12-13.92,15.64-13.92,7.55,0,12.54,3.48,14.38,8.73.59,1.91.13,2.7-1.71,3.42l-4.2,1.38c-1.91.72-2.76.26-3.48-1.64-.92-2.24-2.7-3.35-5.39-3.35q-5.32,0-5.32,4.92v9.2c0,3.28,1.77,4.93,5.32,4.93,2.69,0,4.47-1.12,5.39-3.35.72-1.84,1.57-2.17,3.48-1.58l4.27,1.31c1.84.59,2.43,1.71,1.71,3.49-2.43,5.78-7.49,8.67-15.11,8.67-10,0-15-4.67-15-13.93Z"
      />
      <path
        className={classes.pathcls1}
        d="M147.85,32c0-8.74,5.45-13.86,15.63-13.86s15.7,5.12,15.7,13.86v8.47c0,8.74-5.45,13.8-15.7,13.8s-15.63-5.13-15.63-13.8Zm21.41-.13c0-3.29-1.9-4.93-5.78-4.93s-5.71,1.64-5.71,4.93v8.67q0,4.92,5.71,4.92c3.88,0,5.78-1.64,5.78-4.92Z"
      />
      <path
        className={classes.pathcls1}
        d="M200.89,18.11q14.19,0,14.19,13.6V50.82c0,1.84-.59,2.43-2.37,2.43h-5.12c-1.84,0-2.43-.59-2.43-2.43V32c0-3.28-1.84-4.92-5.58-4.92a16.39,16.39,0,0,0-4.6.59c-.13,0-.2.06-.2.26V50.82c0,1.84-.59,2.43-2.43,2.43h-5.06c-1.84,0-2.43-.59-2.43-2.43V24.28c0-2.17.53-3,2.63-3.81A35,35,0,0,1,200.89,18.11Z"
      />
      <path
        className={classes.pathcls1}
        d="M234.89,18.11c7.23,0,12.09,2.36,14.59,7,.85,1.57.39,2.75-1.32,3.48l-3.87,1.64c-1.91.72-2.24.53-3.35-1.05s-3.09-2.5-5.72-2.5c-3.22,0-4.86.79-4.86,2.37,0,1.9,1.58,2.49,6.37,3,9.53,1,13.93,3.42,13.93,10.78,0,6.89-5.39,11.43-14.78,11.43-7.95,0-13-2.63-15.11-7.82-.59-1.64-.07-2.69,1.58-3.29l4-1.31c1.65-.59,2.43-.26,3.22,1.38s2.76,2.5,6,2.5c3.74,0,5.65-.92,5.65-2.7,0-2.1-1.51-2.62-7-3.15a21,21,0,0,1-9.27-2.5c-2.69-1.51-4.2-4.27-4.2-8.27C220.77,22.44,225.76,18.11,234.89,18.11Z"
      />
      <path
        className={classes.pathcls1}
        d="M283.19,19.09c1.78,0,2.37.59,2.37,2.43V53.78c0,9.13-4.73,14.58-15,14.58-6.83,0-11.5-2-14.06-6.11-1.11-1.64-.79-3,1-3.88l3.74-1.9c1.84-1,2.7-.72,3.81.85a6.14,6.14,0,0,0,5.32,2.5c3.48,0,5.26-1.77,5.26-5.32V51.81a31.93,31.93,0,0,1-6.11.46q-14.19,0-14.19-13.6V21.52c0-1.84.59-2.43,2.43-2.43h5.06c1.84,0,2.43.59,2.43,2.43V38.34c0,3.28,1.84,4.93,5.58,4.93a25.9,25.9,0,0,0,4.8-.46V21.52c0-1.84.59-2.43,2.43-2.43Z"
      />
      <path
        className={classes.pathcls1}
        d="M305.31,18.11c7.23,0,12.09,2.36,14.59,7,.85,1.57.39,2.75-1.32,3.48l-3.87,1.64c-1.91.72-2.24.53-3.35-1.05s-3.09-2.5-5.72-2.5c-3.22,0-4.86.79-4.86,2.37,0,1.9,1.58,2.49,6.37,3,9.53,1,13.93,3.42,13.93,10.78,0,6.89-5.39,11.43-14.78,11.43-7.95,0-13-2.63-15.11-7.82-.59-1.64-.07-2.69,1.58-3.29l4-1.31c1.64-.59,2.43-.26,3.22,1.38s2.76,2.5,6,2.5c3.74,0,5.65-.92,5.65-2.7,0-2.1-1.51-2.62-7-3.15a21,21,0,0,1-9.27-2.5c-2.69-1.51-4.2-4.27-4.2-8.27C291.19,22.44,296.18,18.11,305.31,18.11Z"
      />
      <path
        className={classes.pathcls1}
        d="M337.51,11.21c1.77,0,2.36.59,2.36,2.43v5.45H346c1.78,0,2.37.59,2.37,2.43v3.94c0,1.84-.59,2.43-2.37,2.43h-6.11v11c0,4.93,1.52,6.11,6.05,6.11,1.84,0,2.43.59,2.43,2.5v4.13c0,1.91-.59,2.44-2.43,2.5-5.72.2-9.85-.85-12.29-3S330,45,330,39.32V27.89h-3.49c-1.83,0-2.43-.59-2.43-2.43V21.52c0-1.84.6-2.43,2.43-2.43H330V13.64c0-1.84.65-2.43,2.43-2.43Z"
      />
      <rect
        className={classes.pathcls2}
        height="14.27"
        rx="4.22"
        transform="translate(91.27 85.29) rotate(-180)"
        width="21.4"
        x="34.94"
        y="35.51"
      />
      <rect
        className={classes.pathcls1}
        height="14.27"
        rx="4.22"
        transform="translate(77 122.31) rotate(180)"
        width="14.27"
        x="31.37"
        y="54.02"
      />
      <rect
        className={classes.pathcls3}
        height="14.27"
        rx="4.22"
        transform="translate(35.67 52.51) rotate(180)"
        width="35.67"
        y="19.12"
      />
      <rect
        className={classes.pathcls4}
        height="14.78"
        rx="4.22"
        transform="translate(79.64 14.78) rotate(180)"
        width="57.08"
        x="11.28"
      />
    </svg>
  );
}
