import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';

import { HomePageSearchBar } from '@backstage/plugin-search';
import { 
  SearchContextProvider,
} from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import LogoIcon from './../Root/LogoIcon';
import { LogoVConsyst } from '../Root/LogoCustom';

const useStyles = makeStyles(theme => ({
    searchBarInput: {
      maxWidth: '60vw',
      margin: 'auto',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '50px',
      boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
      borderStyle: 'none'
    }
  }));
  
  const useLogoStyles = makeStyles(theme => ({
    container: {
      margin: theme.spacing(5, 0),
      minheight: '600px'
    },
    svg: {
      width: 'auto',
      height: 100,
    },  
  }));

export const HomePage = () => {
  const classes = useStyles();
  const { container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<LogoVConsyst  />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[{
                    url: 'https://portal.azure.com',
                    label: 'Azure',
                    icon: <img src="azure.ico" width="30px" />  ,
                  },
                  {
                    url: 'https://dev.azure.com/vconsyst/VConsyst%20Dynamics',
                    label: 'Azure DevOps',
                    icon: <img src="devops.ico" width="30px" />,
                  },
                  {
                    url: 'http://wiki.vconsyst.nl/',
                    label: 'Wikipedia',
                    icon: <LogoIcon />,
                  },
                  {
                    url: 'https://portal.azure.com/#browse/microsoft.insights%2Fcomponents',
                    label: 'Application insights',
                    icon: <img src="appinsights.ico" width="60px" />,
                  },
                  {
                    url: 'https://login.mailchimp.com/',
                    label: 'Mailchimp',
                    icon: <img src="mailchimp.ico" width="40px" />,
                  },
                  {
                    url: 'https://www.figma.com/design/CccspqRQmigYe1BOs1u4Hh/Digitale-milieupas-Alkmaar?node-id=0-1&t=ow8jZarJ6WMCRwrB-0',
                    label: 'Figma',
                    icon: <img src="figma.ico" width="30px" />,
                  },
                  {
                    url: 'https://make.powerapps.com',
                    label: 'Power platform',
                    icon: <img src="powerapps.ico" width="30px" />,
                  },
                  {
                    url: 'https://registry.terraform.io/',
                    label: 'Terraform',
                    icon: <img src="terraform.ico" width="50px" />,
                  }
                ]}
                />
              </Grid>          
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
